// $mult: 0.4;

.VideoScreen {
  position: absolute;
  pointer-events: initial;
  // width: 1600px * $mult;
  // height: 900px * $mult;

  // Updated position
  // (X=5.866667,Y=3.300000)
  width: 586.6667px;
  height: 330px;
  background-color: #222;
  border: 2px solid transparent;
  pointer-events: none !important;
  user-select: none;

  &:hover {
    background-color: #333;
    border: 2px solid white;
  }
}
