.spot {
  position: absolute;
  pointer-events: initial;
  // cursor: pointer;

  // transition: all 0.25s ease-out;

  &.visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 1s linear;
  }

  &.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 1s, opacity 1s linear;
  }

  &:hover {
    z-index: 100 !important;

    .bg {
      background-color: rgba($color: #fff, $alpha: 1);
    }

    .dot {
      width: 14px;
      height: 14px;
    }

    .label {
      opacity: 0;
    }

    .content {
      display: block;
    }
  }

  .label {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 150px;
    text-align: center;
    word-wrap: normal;
    color: white;
    font-weight: bold;
    transform: translate(-50%, calc(-100% - 44px));
    pointer-events: none;
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 56px;
    height: 56px;
    background-color: rgba($color: #000000, $alpha: 0.2);
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    transform: translate(-50%, -50%);
    // transition: background-color .25s ease-out;
  }

  .ring {
    position: absolute;
    top: 0;
    left: 0;
    width: 56px;
    height: 56px;
    background-color: white;
    clip-path: polygon(
      /* outter */ 50% 0%,
      100% 50%,
      50% 100%,
      /* inner */ 50% calc(100% - 4px),
      calc(100% - 4px) 50%,
      50% 4px,
      4px 50%,
      50% calc(100% - 4px),
      /* outter */ 50% 100%,
      0% 50%
    );
    transform: translate(-50%, -50%);
  }

  .dot {
    position: absolute;
    top: 0;
    left: 0;
    width: 12px;
    height: 12px;
    background-color: white;
    border-radius: 6px;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    transform: translate(-50%, -50%);
    // transition: width .25s ease-out, height .25s ease-out;
  }

  .content {
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translate(-50%, 14px);
  }
}
