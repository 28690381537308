$icon-size: 16px;

.button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;

  .ring {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    left: 20px;
    bottom: 16px;
    border: 3px solid white;
    width: 56px;
    height: 56px;
    border-radius: 99px;
    box-sizing: border-box;
    padding-left: 4px;
  }

  .play {
    width: $icon-size;
    height: $icon-size;
    border-style: solid;
    box-sizing: border-box;
    border-width: calc($icon-size/2) 0px calc($icon-size/2) $icon-size;
    border-color: transparent transparent transparent white;
  }
}
