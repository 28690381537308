.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  .logo {
    position: absolute;
    top: 32px;
    left: 32px;
    height: 40px;
    width: auto;
    user-select: none;
  }

  .center {
    display: none;
    position: absolute;
    top: 32px;
    left: 50%;
    transform: translateX(-50%);
    color: white;

    @media screen and (min-width: 560px) {
      display: block;
    }
  }

  .right {
    position: absolute;
    display: flex;
    align-items: center;
    top: 32px;
    right: 32px;
    color: white;
  }

  .button {
    cursor: pointer;
    user-select: none;
    padding: 8px 16px;
    box-sizing: content-box;
    border: 1px rgba($color: #fff, $alpha: 0) solid;

    &:hover {
      background-color: rgba($color: #fff, $alpha: 0.1);
      border: 1px rgba($color: #fff, $alpha: 1) solid;
    }
  }

  .link {
    display: block;
    margin-right: 16px;
    color: #fff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .langButton {
    cursor: pointer;
    user-select: none;
    //padding: 8px 12px;
    width: 40px;
    height: 40px;
    line-height: 38px;
    border: 1px rgba($color: #fff, $alpha: 1) solid;
    border-radius: 20px;
    box-sizing: border-box;
    text-align: center;
    // opacity: 0.5;
    &:hover {
      background-color: rgba($color: #fff, $alpha: 0.1);
      // opacity: 1;
    }
  }
}
