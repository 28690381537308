.ThreeViewer {
  position: relative;
  width: 100%; 
  height: 100%;

  canvas {
    width: 100% !important;
    height: 100% !important;
    display: block;
  }

  .container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    display: block;
    pointer-events: none;
  }

  &.loading {
    canvas {
      display: none;
    }
  }

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .curtain {
    background-color: lightblue;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
