.App {
  height: 100vh;

  .welcomeText {
    color: #fff;

    .welcomeLine1 {
      font-size: 5vw;
      // text-transform: uppercase;
      margin-bottom: -3vw;
    }
    .welcomeLine2 {
      font-size: 12vw;
      font-weight: bold;
    }
  }
}
