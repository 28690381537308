.WelcomeInfo {
  background-color: white;
  max-width: 640px;
  box-sizing: border-box;
  padding: 0 16px 16px 16px;

  .imageContainer {
    position: relative;
  }

  .image {
    position: relative;
  }

  .help {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
  }
}