.player {
  position: relative;
  width: 75vw;
  padding-top: 100% *  9 / 16;

  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}