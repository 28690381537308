.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  .center {
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    color: white;

    @media screen and (min-width: 544px) {
      bottom: 32px;
    }
  }

  .right {
    position: absolute;
    top: 32px;
    right: 32px;
    color: white;
  }

  .close {
    position: absolute;
    top: 12px;
    right: 12px;
  }
}
