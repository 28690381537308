.finish {
  position: relative;
  width: 143px;
  height: 105px;
  user-select: none;
  cursor: pointer;
  // border: 1px solid black;

  .image {
    background-color: #ccc;
  }

  .icon {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 24px;
    height: 24px;
    line-height: 20px;
    text-align: center;
    background-color: #444;
    border-radius: 16px;
    padding-top: 3px;
    box-sizing: border-box;
  }

  .label {
    position: absolute;
    // left: 8px;
    bottom: 6px;
    width: 100%;
    text-align: center;
    color: #333;
    font-weight: bold;
    font-size: 14px;
  }
}

.container {
  display: flex;
  gap: 2px;
  padding-bottom: 4px;

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #e9ecef;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
}
