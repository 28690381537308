.editor {
  width: 328px;
  background-color: white;
  padding: 8px 16px 12px 16px;

  @media screen and (min-width: 544px) {
    width: 512px;
  }
}

.label {
  text-align: left;

  .name {
    font-weight: bold;
    text-transform: uppercase;
  }
  .designer {
    font-size: 12px;
  }
}

.tab {
  height: 48px;
  padding: 0;
  margin-right: 24px;
  :last-child {
    margin-right: 0;
  }
}
