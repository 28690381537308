.info {
  background-color: white;
  width: 340px;
  box-sizing: border-box;

  &.horizontal {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    width: 680px;
  }

  &.wide {
    /*     display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr; */
    // width: 680px;

    @media screen and (min-width: 544px) {
      width: 510px;
    }
  }

  .imageContainer {
    position: relative;
  }

  .image {
    background-color: #ccc;
  }

  .main {
    padding: 16px 20px 16px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p,
    ul {
      margin: 0.5em 0;
    }
  }
}
